// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const th = {
  game: `เกม`,
  games: `เกม`,
  sport: `กีฬา`,
  center: `ศูนย์`,
  all_games: `เกมส์ทั้งหมด`,
  game_history: `ประวัติเกม`,
  coupon_code: `รหัสคูปอง`,
  telegram: `เทเลแกรม`,
  customer_service: `บริการลูกค้า`,
  player: `ผู้เล่น`,
  winner: `ผู้ชนะ`,
  all_bets: `เดิมพันทั้งหมด`,
  my_bets: `เดิมพันของฉัน`,
  name: `ชื่อ`,
  time: `เวลา`,
  bet_amount: `จำนวนเงินเดิมพัน`,
  prediction: `การทำนาย`,
  payout: `การจ่ายเงิน`,
  login: `เข้าสู่ระบบ`,
  register: `ลงทะเบียน`,
  username_placeholder: `ชื่อผู้ใช้`,
  please_input_your_x: `กรุณากรอก {{x}} ของคุณ`,
  captcha: `แคปช่า`,
  forgot_password: `ลืมรหัสผ่าน`,
  minor_note: `หากต้องการเยี่ยมชมเว็บไซต์นี้ ตรวจสอบให้แน่ใจว่าคุณมีอายุมากกว่า 18 ปีและเห็นด้วย`,
  terms_of_service: `เงื่อนไขการให้บริการ`,
  dont_have_account: `ยังไม่มีบัญชี`,
  sign_up: `ลงชื่อ`,
  username: `ชื่อผู้ใช้`,
  email: `อีเมล`,
  full_name: `ชื่อเต็ม`,
  confirm_18: `ฉันยืนยันว่าฉันอายุ 18 ปีและฉันได้อ่าน`,
  already_have_account: `มีบัญชีอยู่แล้ว?`,
  share: `แชร์`,
  deposit: `เงินฝาก`,
  bonus: `โบนัส`,
  app: `แอป`,
  load_more: `โหลดเพิ่มเติม`,
  details: `รายละเอียด`,
  helps: `ช่วยเหลือ`,
  kyc_policy: `นโยบายความเป็นส่วนตัว`,
  privacy_policy: `นโยบายความเป็นส่วนตัว`,
  responsible_gaming: `การเล่นเกมอย่างมีความรับผิดชอบ`,
  terms_and_condition: `ข้อกำหนดและเงื่อนไข`,
  faq: `คำถามที่พบบ่อย`,
  about_us: `เกี่ยวกับเรา`,
  amount: `จำนวน`,
  extra: `พิเศษ`,
  deposit_bonus: `โบนัสเงินฝาก`,
  do_not_participate: `อย่าเข้าร่วมโปรโมชั่น`,
  payment_problem: `ปัญหาการชำระเงิน`,
  click_for_help: `คลิกเพื่อขอความช่วยเหลือ`,
  deposit_notes: `หมายเหตุการฝากเงิน`,
  available_balance: `ยอดเงินคงเหลือ`,
  withdraw: `ถอน`,
  withdrawal_notes: `หมายเหตุการถอนเงิน`,
  withdrawal_note1: `จำนวนการถอนเงินในปัจจุบัน`,
  withdrawal_note1_1: `วีไอพี`,
  withdrawal_note2: `ค่าธรรมเนียมการถอน:`,
  min: `ขั้นต่ำ`,
  max: `สูงสุด`,
  opening_times: `เวลาเปิด:`,
  notifications: `การแจ้งเตือน`,
  invalid_x: `ไม่ถูกต้อง {{x}}`,
  captcha_not_match: `แคปต์ชาไม่ตรงกัน`,
  invalid_x_must_be_atleast_y_characters: `ไม่ถูกต้อง {{x}}, ต้องเป็นอย่างน้อย {{y}} ตัวอักษร`,
  x_is_required: `{{x}} เป็นสิ่งจำเป็น`,
  phone_number: 'หมายเลขโทรศัพท์',
  phone_num_x_max: `หมายเลขโทรศัพท์สูงสุด {{x}} หลัก`,
  email_sent: `ส่งอีเมลแล้ว โปรดตรวจสอบอีเมลของคุณ`,
  enter_valid_email: `กรุณาใส่อีเมลที่ถูกต้อง.`,
  confirm: `ยืนยัน`,
  password_atleast_6_characters: `รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร`,
  password_reset_success: `รีเซ็ตรหัสผ่านสำเร็จ!.`,
  new_password: `รหัสผ่านใหม่`,
  bank: 'ธนาคาร',
  bank_transfer: `โอนเงินผ่านธนาคาร`,
  bank_name: `ชื่อธนาคาร`,
  account_name: `ชื่อบัญชี`,
  account_number: `หมายเลขบัญชี`,
  drop_or_select: `วางหรือเลือกไฟล์`,
  drop_files_here: `วางไฟล์ที่นี่`,
  transfer_to: `โอนไปที่`,
  upload_receipt: `อัพโหลดใบเสร็จรับเงิน`,
  select_bonus: `เลือกโบนัส`,
  no_promotion: `ไม่มีโปรโมชั่น`,
  please_upload_receipt: `กรุณาอัพโหลดใบเสร็จรับเงิน`,
  bank_account: `บัญชีธนาคาร`,
  bank_branch: `Bank Branch`,
  pay_id: `Pay Id`,
  add_account: `เพิ่มบัญชี`,
  account_holder_name: `ชื่อเจ้าของบัญชี`,
  name_of_the_account_holder: `ชื่อเจ้าของบัญชี`,
  fill_in_bank_account: `กรอกบัญชีธนาคาร`,
  fill_in_pay_id: `Fill in Pay Id`,
  fill_in_bank_branch: `Fill in Bank Branch`,
  withdraw_information: `ข้อมูลการถอน`,
  note: `บันทึก`,
  amount_withdrawn: `จำนวนเงินที่ถอนออก`,
  amount_no_exceed_balance: `จำนวนเงินที่ถอนไม่ควรเกินยอดคงเหลือที่มีอยู่`,
  minimum_withdraw_amount_is_x: `จำนวนเงินถอนขั้นต่ำคือ {{x}}`,
  transaction_history: `ประวัติการทำรายการ`,
  date_from: `วันที่จาก`,
  date_to: `ถึงวันที่`,
  account_name_must_be_4: `ชื่อบัญชีต้องมีความยาวมากกว่า 4 ตัวขึ้นไป`,
  account_number_must_be_x: `หมายเลขบัญชีต้องมีความยาว 10-14 ตัว`,
  payment_method: `วิธีการชำระเงิน`,
  no_bank_account: `คุณไม่มีบัญชีใด ๆ ที่จะถอนออก เพิ่มทันที`,
  please_select_bank: `กรุณาเลือกบัญชีธนาคาร`,

  bet_history: `ประวัติการเดิมพัน`,
  game_provider: `ผู้ให้บริการเกม`,
  bet_count: `นับเดิมพัน`,
  win_loss: `ชนะ/แพ้`,
  rebate: `ส่วนลด`,
  turnover: `เทิร์นโอเวอร์`,
  game_category: `หมวดเกม`,

  you_have_x_unread_messages: `คุณมี{{x}}ข้อความที่ยังไม่ได้อ่าน`,
  deposit_amount_between_x_and_y: `จำนวนเงินฝากจะต้องอยู่ระหว่าง {{x}} ถึง {{y}}`,
  minimum_amount_is_x_y: `จำนวนเงินขั้นต่ำคือ {{x}}{{y}}`,

  // all
  all: `ทั้งหมด`,
  title: `ชื่อ`,
  date: `วันที่`,
  type: `หมวด`,
  status: `สถานะ`,
  remark: `ข้อสังเกต`,
  category: `หมวดหมู่`,
  please_select_x: `โปรดเลือก {{x}}`,
  save: `บันทึก`,

  change_password: `เปลี่ยนรหัสผ่าน`,
  current_password: `รหัสผ่านปัจจุบัน`,
  confirm_password: `ยืนยันรหัสผ่านใหม่`,
  submit: `ส่ง`,
  password_changed: `เปลี่ยนรหัสผ่านแล้ว`,
  x_must_be_y_characters: `{{x}} ต้องมีอักขระอย่างน้อย {{y}} ตัว`,
  reset_password: `รีเซ็ตรหัสผ่าน`,
  casino: `คาสิโน`,
  slots: `สล็อต`,
  live_casino: `คาสิโนสด`,
  fishing: `ยิงปลา`,
  sports: `กีฬา`,
  cards: `การ์ด`,
  clear: `ชัดเจน`,
  search: `ค้นหา`,
  new: `ใหม่`,
  logout: `ออกจากระบบ`,
  choose_date: `เลือกวันที่`,
  select_both_date: `เลือกทั้งวันที่เริ่มต้นและถึง`,
  from: `จาก`,
  to: `ถึง`,
  apply: `นำมาใช้`,
  promotions: `โปรโมชั่น`,
  learn_more: `เรียนรู้เพิ่มเติม`,
  password_doesnt_match: `รหัสผ่านไม่ตรงกัน`,
  cancel: `ยกเลิก`,
  confirm_to_pay: `ยืนยันการชำระเงิน`,
  deposit_amount: `จำนวนเงินฝาก`,
  loading_please_wait: `กำลังโหลด กรุณารอสักครู่`,
  account: `บัญชี`,
  profile: `ประวัติโดยย่อ`,
  bank_list: `รายชื่อธนาคาร`,
  in_maintenance: `ในการบำรุงรักษา`,
  birthday: `วันเกิด`,
  birthday_bonus: `ป้อนวันเกิดของคุณเพื่อรับโบนัส! 🎉🎁`,
  referral_code: `รหัสอ้างอิง`,
  referral: `การอ้างอิง`,
  my_referral: `การอ้างอิงของฉัน`,
  my_claim: `การเรียกร้องของฉัน`,
  claim_profit: `เรียกร้องผลกำไร`,
  available_profit: `กำไรที่มีอยู่`,
  claimed_profit: `กำไรที่เรียกร้อง`,
  all_time_profit: `กำไรตลอดเวลา`,
  referral_note_on_x: `รับผลกำไรทุกครั้งที่เพื่อนของคุณเล่นบน {{x}}`,
  tier: `ชั้น`,
  commission: `คณะกรรมการ`,
  referred_member: `สมาชิกที่ได้รับการแนะนำ`,
  profit_earned: `กำไรที่ได้รับ`,
  account_information: `ข้อมูลเกี่ยวกับบัญชี`,
  invite_friends: `เชิญเพื่อน ๆ`,
  how_it_work: `มันทำงานอย่างไร?`,
  invite_note: `รับโบนัสการแนะนำพิเศษเมื่อเพื่อนของคุณสมัครใช้รหัสอ้างอิงของคุณ!`,
  share_via: `แบ่งปันผ่านทาง`,
  referral_link: `ลิงค์ผู้อ้างอิง`,
  total_profit: `กำไรรวม`,
  copied: `คัดลอกแล้ว`,
  nothing_to_claim: `ไม่มีอะไรจะเรียกร้อง`,
  claim_history: `ประวัติการเรียกร้อง`,
  downline: `ดาวน์ไลน์ของฉัน`,
  last_x_digit_bank_ref_number: `{{x}} หลักสุดท้ายของหมายเลขอ้างอิงธนาคาร`,
  bank_ref_number_validation_x: `ต้องระบุหมายเลขอ้างอิงธนาคารและต้องมีความยาวอักขระ {{x}} ตัว`,


  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  // app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  product: `product`,
  invoice: `invoice`,
  // details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default th;
