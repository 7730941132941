import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

import conObj from '../utils/connection-assistant';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: !!localStorage.getItem('accessToken'),
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, userProfile } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      userProfile,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getAccountProfile: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);
        const referralCode = urlParams.get('aff');

        if (referralCode) {
          localStorage.setItem('referralCode', referralCode);
        }

        if (window.location.pathname.indexOf('auth/reset-password') !== -1) {
          const token = window.location.pathname.match(/[^]+$/)[0];
          localStorage.setItem('resetToken', token);
        }

        if (accessToken) {
          setSession(accessToken);

          const response = await conObj.post('profile.json');
          // const { first_name, username, full_name, last_name, email } = response.data.data.data;

          // const getProfile = () => {
          //   conObj
          //     .post('profile.json')
          //     .then((res) => {
          //       if (res.status === 200) {
          //         const {
          //           username,
          //           first_name: firstName,
          //           last_name: lastName,
          //           email,
          //           photo,
          //           active,
          //           status,
          //           full_name: fullName,
          //           UserProfile,
          //           Currency,
          //         } = res.data?.data?.data;
          //         // process profile data here
          //         localStorage.setItem('username', username);
          //         localStorage.setItem('firstName', firstName);
          //         localStorage.setItem('lastName', lastName);
          //         localStorage.setItem('email', email);
          //         localStorage.setItem('photo', photo);
          //         localStorage.setItem('active', active);
          //         localStorage.setItem('status', status);
          //         localStorage.setItem('fullName', fullName);
          //         localStorage.setItem('noHp', UserProfile?.no_hp);
          //         localStorage.setItem('currencyCode', Currency?.code);
          //         localStorage.setItem('currencyName', Currency?.name);
          //         localStorage.setItem('currencySymbol', Currency?.symbol);
          //       }
          //     })
          //     .catch((err) => {
          //       console.log(`Server Error: ${err.message}`);
          //     });
          // };

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: response.data.data.data,
              userProfile: response.data.data.data,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              userProfile: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            userProfile: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (
    data,
    loginData = { token: null, firstName: '', username: '', fullName: '', lastName: '', email: '' }
  ) => {
    localStorage.removeItem('accessToken');

    let errMsg = '';

    let { token } = loginData;
    let { firstName, username, fullName, lastName, email } = loginData;

    if (!token) {
      const response = await conObj.post('login.json', data);
      const responseData = response.data?.data?.data;
      errMsg = response.data?.data?.msg;
      token = response.data?.data?.token;

      firstName = responseData?.first_name;
      username = responseData?.username;
      fullName = responseData?.full_name;
      lastName = responseData?.last_name;
      email = responseData?.email;
    }

    if (token) {
      setSession(token);

      window.location.reload();

      dispatch({
        type: 'LOGIN',
        payload: {
          user: { first_name: firstName, username, full_name: fullName, last_name: lastName, email },
          userProfile: { first_name: firstName, username, full_name: fullName, last_name: lastName, email },
        },
      });
    } else throw new Error(errMsg);
  };

  const register = async (payload) => {
    localStorage.removeItem('accessToken');

    const response = await conObj.post('register.json', payload);

    const { token, data } = response.data.data;

    if (token) {
      setSession(token);

      // window.location.reload();

      dispatch({
        type: 'LOGIN',
        payload: {
          user: data,
          userProfile: data,
        },
      });
    } else throw new Error(response.data?.data?.msg);
  };

  const logout = async () => {
    setSession(null);
    window.location.reload();
    dispatch({ type: 'LOGOUT' });
  };

  const getAccountProfile = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        const response = await conObj.post('profile.json');

        const responseData = response.data?.data?.data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: responseData,
            userProfile: responseData,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
          userProfile: null,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getAccountProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
