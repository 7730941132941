import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, Typography, MenuItem } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import MenuPopover from '../../components/MenuPopover';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import { fNumberDecimal } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function AccountInfo({ open, handleClose }) {
  const navigate = useNavigate();

  const { userProfile, logout } = useAuth();

  const { translate } = useLocales();


  const { memberBalance } = useSelector((x) => x.lookup);

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenuItem-root': {
            typography: 'body2',
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userProfile?.full_name}
          </Typography>
          <Typography variant="body2" sx={{ color: '#cbd3dc' }} noWrap>
            {userProfile?.email}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.primary' }} noWrap>
            {`${memberBalance?.currencyCode} ${
              memberBalance?.displayBalance ? fNumberDecimal(memberBalance?.displayBalance) : 0
            }`}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed', borderColor: 'white' }} />

        <MenuItem
          onClick={() => {
            navigate('/user/account');
            handleClose();
          }}
          sx={{ m: 1 }}
        >
          {translate('account')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/referral');
            handleClose();
          }}
          sx={{ m: 1 }}
        >
          {translate('referral')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate('/history/tab');
            handleClose();
          }}
          sx={{ m: 1 }}
        >
          History
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
