import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import Iconify from '../../../components/Iconify';
import { useSelector } from '../../../redux/store';

import palette from '../../../theme/palette';

const DashboardHeader = () => {
  const { siteSettings } = useSelector((x) => x.lookup);
  const navigate = useNavigate();
  return (
    <Box sx={{ zIndex: -1 }}>
      <img
        className="brand-logo"
        src={require('../../../assets/brand-logo.png')}
        alt="Loading"
        style={{ opacity: 0.9, margin: '0px auto', padding: '10px 0px' }}
        aria-hidden="true"
        onClick={() => navigate('/')}
      />
      <Box sx={{ width: '100%', opacity: 0.9 }}>
        {siteSettings?.announcement_text?.WalkPromotion?.content && (
          <Stack
            direction="row"
            alignItems={'center'}
            sx={{
              p: '0px 8px',
              background: palette.home.announcement.bg,
              cursor: siteSettings?.announcement_text?.WalkPromotion?.url ? 'pointer' : 'none',
            }}
            onClick={() => {
              if (siteSettings?.announcement_text?.WalkPromotion?.url) {
                window.open(siteSettings?.announcement_text?.WalkPromotion?.url, '_blank');
              }
            }}
          >
            <Iconify
              icon={'pepicons-pencil:megaphone'}
              color={palette.home.announcement.color}
              width={25}
              height={25}
              sx={{ mr: 1, minWidth: '25px', minHeight: '25px' }}
            />
            <Box className="marquee" sx={{ width: '100%', opacity: 1 }}>
              <Typography sx={{ color: 'white', fontWeight: 400 }}>
                {siteSettings?.announcement_text?.WalkPromotion?.content}
              </Typography>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default DashboardHeader;
