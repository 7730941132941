import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@mui/material';
// import { LiveChatWidget } from '@livechat/widget-react';
import ReactPixel from 'react-facebook-pixel';

// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

import AccountInfo from '../../views/user/AccountInfo';

import DashboardHeader from './header';

import Image from '../../components/Image';
import palette from '../../theme/palette';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { setGameCategories, setAllGames, setActiveTab } from '../../redux/slices/game';

import DepositWithdrawDialog from './header/DepositWithdrawDialog';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from './header/withdraw/WithdrawFormDialog';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  backgroundImage: `url(${require('../../assets/bg.png')})`,
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  // paddingBottom: HEADER.MOBILE_HEIGHT,
  height: 'calc(100% - 88px - 44px)',
  // height: 'auto',
  [theme.breakpoints.up('lg')]: {
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    // paddingBottom: HEADER.MOBILE_HEIGHT,
  },
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: '40px',
  cursor: 'pointer',
}));

const BottomMenuLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.4vw',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '.6vw',
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const [menuIndex, setMenuIndex] = useState(1);

  const verticalLayout = themeLayout === 'vertical';

  const intervalRef = useRef(null);

  useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setMenuIndex(1)
        break;
      case '/history/tab':
        setMenuIndex(2);
        break;
      case '/promotion':
        setMenuIndex(3);
        break;
      case '/chatroom':
        setMenuIndex(4);
        break;
      case '/user/account':
        setMenuIndex(5);
        break;
      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getAllGames(), getAngPauPromotion()];

    // if (isAuthenticated) promises.push(getUserMemberBalance());

    dispatch(openLoadingScreen());
    Promise.all(promises).finally(() => dispatch(closeLoadingScreen()));
  }, [isAuthenticated]);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) {
      const promises = [getUserMemberBalance()];

      intervalRef.current = setInterval(() => {
        Promise.all(promises)
        // getUserMemberBalance()
      }, 30000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    if (siteSettings?.config_analytics) {
      const _ga = siteSettings?.config_analytics?.split(',');

      // Multiple products (previously known as trackers)
      ReactGA.initialize(
        _ga.map((item) => ({
          // measurement ID
          trackingId: item,
          // gaOptions: {...}, // optional
          // gtagOptions: {...}, // optional
        }))
        //   [
        //   {
        //     // measurement ID
        //     trackingId: _ga[0],
        //     // gaOptions: {...}, // optional
        //     // gtagOptions: {...}, // optional
        //   },
        // ]
      );
    }

    if (siteSettings?.config_fb_pixel) {
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init(siteSettings?.config_fb_pixel, options);

      ReactPixel.pageView(); // For tracking page view
    }

    // if (siteSettings?.config_livechat) {
    //   const respondIo = JSON.parse(siteSettings?.config_livechat);
    //   const script = document.createElement('script');

    //   script.id = respondIo?.id;
    //   script.addEventListener('load', () => {
    //     const el = document.getElementById('_68625');

    //     if (el) {
    //       el.setAttribute('style', 'z-index:1200 !important');
    //     }
    //   });
    //   script.src = respondIo?.src;
    //   script.async = true;

    //   document.head.appendChild(script);
    // }
  }, [siteSettings]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;
        dispatch(setAllGames(_allGames));
        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpen = () => {
    setOpenSettings(!openSettings);
  };

  const handleClose = () => {
    setOpenSettings(false);
  };

  if (verticalLayout) {
    return (
      <>
        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'block' },
        height: '100%',
        // backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader />
      <MainStyle collapseClick={collapseClick}>
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />
      </MainStyle>

      <Box
        sx={{
          borderTop: '1px solid #dd0000',
          width: '100%',
          height: 'auto',
          backgroundColor: palette.bottomMenu.bgColor,
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 100,
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          display: 'block',
          p: '5px 0',
          right: 0,
        }}
      >
        <Grid container>
          <Grid
            onClick={() => {
              dispatch(setActiveTab('Popular'));
              navigate('/');
            }}
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 1 ? '#34282C' : '#000'}`,
            }}
          >
            <Image
              src={require('../../assets/menu/home_icon.png')}
              sx={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>HOME</BottomMenuLabel>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 2 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              if (!isAuthenticated) {
                navigate('/auth/login-unprotected');
                setMenuIndex(2);
            } else {
                navigate('/history/tab');
              }
            }}
          >
            <Image
              src={require('../../assets/menu/history.png')}
              sx={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>HISTORY</BottomMenuLabel>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 3 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              navigate('/promotion');
            }}
          >
            <Image
              sx={{
                width: '40px',
                height: '30px',
                cursor: 'pointer',
              }}
              src={require('../../assets/menu/bonus_icon.gif')}
              alt="Loading"
            />
          </Grid>
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 4 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              // if (!isAuthenticated) {
              //   navigate('/auth/login-unprotected');
              // } else {
              navigate('/chatroom');
              // }
            }}
          >
            <Iconify icon={'bx:chat'} sx={{ color: 'red', width: '25px', height: '25px', cursor: 'pointer' }} />
            {/* <Image
              src={require('../../assets/menu/deposit_icon.png')}
              sx={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
            /> */}
            <BottomMenuLabel>LIVE CHAT</BottomMenuLabel>
          </Grid>
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 5 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              if (!isAuthenticated) {
                navigate('/auth/login-unprotected');
                setMenuIndex(5);
            } else {
                navigate('/user/account');
                // handleOpen();
              }
            }}
          >
            <Image
              src={require('../../assets/menu/settings_icon.png')}
              sx={{
                width: '25px',
                height: '25px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>SETTINGS</BottomMenuLabel>
          </Grid>
        </Grid>
        <AccountInfo open={openSettings} handleClose={handleClose} />
      </Box>

      {/* {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={() => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )} */}

      {isOpenTransactionsDialog.open && <DepositWithdrawDialog tab={isOpenTransactionsDialog.activeTab} />}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default DashboardLayout;
