import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, InputBase, Grid, NativeSelect, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';

import useLocales from '../../../../hooks/useLocales';

// Dialog
import InsufficientBalanceDialog from './InsufficientBalanceDialog';
import ConfirmWithdrawDrawer from './ConfirmWithdrawDrawer';

import conObj from '../../../../utils/connection-assistant';
import { useSelector, useDispatch } from '../../../../redux/store';
import { openTransactionsDialog } from '../../../../redux/slices/transactionsDialog';
import { openAddMemberBankDialog } from '../../../../redux/slices/addMemberBankDialog';
import { fCurrency, fNumberCommaSeparated } from '../../../../utils/formatNumber';

import palette from '../../../../theme/palette';
import { getUserMemberBalance } from '../../../../redux/slices/lookup';

const TypographyCustom = styled(Typography)({
  color: '#fff',
  fontSize: '14px',
});

const SuggestedWthdrwBox = styled(Box)(({ theme }) => ({
  backgroundColor: palette.transactionDialog.withdraw.suggestedWthdrwBg,
  padding: '11px 4px',
  borderRadius: '6px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderWidth: '3px',
  borderStyle: 'solid',
}));

const AvailableBalanceTxt = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  fontFamily: 'sans-serif',
  color: palette.transactionDialog.withdraw.availableBalanceTxt,
  marginTop: 10,
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: 'fff',
  fontSize: '1rem',
}));

const WithdrawInputBox = styled(Stack)(({ theme }) => ({
  background: palette.transactionDialog.withdraw.withdrawInputBox.bg,
  border: `3px solid ${palette.transactionDialog.withdraw.withdrawInputBox.border}`,
  borderRadius: '15px',
  boxShadow: `0 0 0 2px ${palette.transactionDialog.withdraw.withdrawInputBox.shadow}`,
  padding: '4px 11px',
  height: '42px',
  marginTop: 8,
  alignItems: 'center',
}));

const WithddrawNote = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: '#000',
  marginTop: 5,
}));

const StyledSelect = styled(NativeSelect)(({ theme }) => ({
  background: '#ffde4a',
  width: '100%',
  padding: '4px 11px',
  marginTop: 8,
  fontWeight: 600,
  borderRadius: '15px',
  border: '3px solid #fff4ba',
  boxShadow: '0 0 0 2px rgba(23,125,220,.2)',
  color: '#000',
}));

const NoBankAccount = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: palette.transactionDialog.withdraw.noBankAccount,
}));

const WithdrawText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  color: palette.transactionDialog.withdraw.withdrawText,
  fontWeight: 700,
}));

const ConfirmBtnBox = styled(Grid)(({ theme }) => ({
  padding: '8px 12px',
  position: 'relative',
  backgroundColor: palette.transactionDialog.withdraw.confirmBtnBg,
  borderRadius: '10px',
  marginTop: '10px',
  cursor: 'pointer',
}));

const FormBox = styled(Box)(({ theme }) => ({
  background: '#FFF3E0',
  padding: '10px',
  border: '1px solid #FFB74D',
  borderRadius: 5,
  marginTop: 10,
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  fontSize: 15,
  background: '#ddd',
  WebkitTextFillColor: '#666',
  color: '#666',
  opacity: 1,
  padding: 10,
  paddingRight: 25,
  width: '100%',
  border: '1px solid #bbb',
  outline: 0,
  borderRadius: 4,
  WebkitAppearance: 'none',
  WebkitUserSelect: 'text',
}));

const ActionButton = styled(ButtonBase)(({ theme }) => ({
  width: '100px',
  padding: '8px 0',
  border: '1px solid #34740e',
  display: 'block',
  borderRadius: '4px',
  background: 'linear-gradient(to bottom,#4ba614,#008c00)',
  cursor: 'pointer',
}));

const ActionButtonText = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  lineHeight: '20px',
  textShadow: '-1px -1px 0 rgba(0,0,0,.3)',
  color: '#fff',
  textTransform: 'uppercase',
}));

const AddAccountBtn = ({ children }) => (
  <button
    style={{
      fontSize: '.75rem',
      padding: '5px',
      border: 'none',
      borderRadius: '5px',
      background: '#dd0000',
      color: 'white',
      cursor: 'pointer',
      fontWeight: 600,
      marginTop: '10px',
    }}
  >
    {children}
  </button>
);

const Withdraw = () => {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { memberBalance } = useSelector((x) => x.lookup);

  const [withdrawAmount, setWithdrawAmount] = useState('0');

  const [openWitdrawForm, setOpenWithdrawForm] = useState(false);

  const [withdrawInfoSaved, setWithdrawInfoSaved] = useState(false);

  const [confirmWithdraw, setConfirmWithdraw] = useState(false);

  const [openInsufficientBalance, setOpenInsufficientBalance] = useState(false);

  const [memberBankAccounts, setMemberBankAccounts] = useState([]);

  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  // const [amountInThousandths, setAmountInThousandths] = useState(false);

  const [currencyWithdraw, setCurrencyWithdraw] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [minWithdrawLimit, setMinWithdrawLimit] = useState(0);

  const [maxWithdrawLimit, setMaxWithdrawLimit] = useState(0);

  const [withdrawLimitMessage, setWithdrawLimitMessage] = useState('');

  useEffect(() => {
    getMemberBankAccounts();
  }, []);

  // useEffect(() => {
  //   if (memberBalance?.currencyCode.toUpperCase() === 'MMK' || memberBalance?.currencyCode.toUpperCase() === 'VND') {
  //     setAmountInThousandths(true);
  //   }
  // }, [memberBalance]);

  // useEffect(() => {
  //   const _currencyWithdraw = memberBankAccounts.find((f) => f.id === selectedBankAccount)?.CurrencyWithdraw;
  //   if (_currencyWithdraw) {
  //     setCurrencyWithdraw(Object.values(_currencyWithdraw));
  //   } else {
  //     setCurrencyWithdraw(null);
  //   }
  // }, [selectedBankAccount]);

  const handleConfirm = () => {
    if (!withdrawAmount.trim()) {
      enqueueSnackbar(translate('x_is_required', { x: `${translate('withdraw')} ${translate('amount')}` }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    if (Number(withdrawAmount) <= 0) {
      enqueueSnackbar(translate('x_is_required', { x: `${translate('withdraw')} ${translate('amount')}` }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    if (Number(memberBalance?.displayBalance) === 0) {
      enqueueSnackbar(`${translate('no_balance')}!`, { variant: 'error', autoHideDuration: 5000 });
      return;
    }

    const _memberBalance = memberBalance?.balance
      ? parseFloat(memberBalance?.balance?.toString()?.replace(/[^0-9.]/g, ''))
      : 0;
    // const _memberBalance = amountInThousandths
    // ? parseFloat(memberBalance?.balance.replace(/[^0-9.]/g, '')) * 1000
    // : parseFloat(memberBalance?.balance.replace(/[^0-9.]/g, ''));
    if (parseFloat(withdrawAmount.trim()) > _memberBalance) {
      enqueueSnackbar(translate('amount_no_exceed_balance'), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    if (currencyWithdraw) {
      if (parseFloat(withdrawAmount.toString().replaceAll(',', '')) < getMinWithdrawAmount()) {
        enqueueSnackbar(translate('minimum_withdraw_amount_is_x', { x: getMinWithdrawAmount() }), {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }

      if (parseFloat(withdrawAmount.toString().replaceAll(',', '')) > getMaxWithdrawAmount()) {
        enqueueSnackbar(translate('maximum_withdraw_amount_is_x', { x: getMaxWithdrawAmount() }), {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }
    }

    if (!selectedBankAccount) {
      enqueueSnackbar(translate('x_is_required', { x: translate('bank_account') }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }

    setConfirmWithdraw(true);
  };

  const getMinWithdrawAmount = () => {
    const _minimumWithdrawAmount = parseFloat(currencyWithdraw[0]?.toString().replace(/[^0-9.]/g, ''));
    if (parseFloat(minWithdrawLimit) > 0) {
      return Math.max(parseFloat(minWithdrawLimit), parseFloat(_minimumWithdrawAmount));
    }

    return _minimumWithdrawAmount;
  };

  const getMaxWithdrawAmount = () => {
    const _maximumWithdrawAmount = parseFloat(
      currencyWithdraw[currencyWithdraw.length - 1]?.toString().replace(/[^0-9.]/g, '')
    );
    if (parseFloat(maxWithdrawLimit) > 0) {
      return Math.min(parseFloat(maxWithdrawLimit), parseFloat(_maximumWithdrawAmount));
    }

    return _maximumWithdrawAmount;
  };

  const getMemberBankAccounts = () => {
    conObj
      .post('members/banks.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            setMemberBankAccounts(res.data?.data?.data);
            // set selected
            setSelectedBankAccount(res.data?.data?.data[0]?.id);

            // set suggested currency withdraw amount
            setCurrencyWithdraw(Object.values(res.data?.data?.currencyWithdraw));

            setMinWithdrawLimit(res.data?.data?.minWithdrawLimit);
            setMaxWithdrawLimit(res.data?.data?.maxWithdrawLimit);

            setWithdrawLimitMessage(res.data?.data?.withdrawLimitMessage);
          } else {
            //
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
      });
  };

  const submitWithdraw = () => {
    const requestData = {
      'transaction-member_bank_id': selectedBankAccount,
      'transaction-amount': parseFloat(withdrawAmount.toString().replaceAll(',', '')),
      // 'transaction-amount': amountInThousandths
      // ? parseFloat(withdrawAmount.trim()) / 1000
      // : parseFloat(withdrawAmount.trim()),
      'transaction-note': '',
    };

    setIsSubmitting(true);

    conObj
      .post('transactions/withdraws.json', requestData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            enqueueSnackbar(res?.data?.data?.msg, { variant: 'success', autoHideDuration: 5000 });
            dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
            getUserMemberBalance();
          } else if (res.data?.data?.data?.validationErrors) {
            enqueueSnackbar(res?.data?.data?.validationErrors.toString(), {
              variant: 'error',
              autoHideDuration: 5000,
            });
          }

          setConfirmWithdraw(false);
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
        dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
        setConfirmWithdraw(false);
      })
      .finally(() => setIsSubmitting(false));
  };

  const toggleDrawer = (open) => {
    setConfirmWithdraw(open);
  };

  const makeCurrencyWithdraw = (amount) => {
    const _amount = amount;
    // const _amount = amountInThousandths ? amount * 1000 : amount;
    return (
      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
        <SuggestedWthdrwBox
          onClick={() => setWithdrawAmount(_amount.toString())}
          sx={{
            borderColor: withdrawAmount === _amount ? '#dd0000' : 'transparent',
            borderRadius: 1,
          }}
        >
          <TypographyCustom sx={{ color: '#000', fontWeight: 700, wordBreak: 'break-all' }}>{`${fCurrency(
            _amount
          )}`}</TypographyCustom>
          {withdrawAmount === _amount && (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: 'absolute',
                bottom: 0,
                color: '#dd0000',
                height: '16px',
                right: 0,
                width: '17px',
                zIndex: 1,
              }}
            >
              <path d="M17 0L0 16H12C14.7614 16 17 13.7614 17 11V0Z" fill="currentColor" />
              <path
                d="M9.35493 11.6942L11.1083 12.8822L13.6758 9.00007"
                stroke="white"
                strokeWidth="0.822792"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </SuggestedWthdrwBox>
      </Grid>
    );
  };

  return (
    <Box p={1} sx={{ maxWidth: '900px', margin: 'auto', pb: '70px !important' }}>
      <Box>
        <Typography sx={{ color: '#FFC107', fontWeight: 700, fontSize: '18px', textTransform: 'uppercase' }}>
          {translate('withdraw')}
        </Typography>

        <Typography sx={{ color: '#FFC107', fontWeight: 700, mt: '10px' }}>{translate('withdrawal_notes')}</Typography>
        <ul>
          <li style={{ color: '#fff', margin: '6px 0 0 18px', fontSize: 15 }}>{translate('withdrawal_note1')}</li>
          <li style={{ color: '#fff', margin: '6px 0 0 18px', fontSize: 15 }}>{translate('withdrawal_note2')}</li>
          <li
            style={{
              color: '#FFC107',
              margin: '6px 0 0 18px',
              fontSize: 15,
              fontWeight: 700,
              animation: 'blink 1.2s linear infinite',
            }}
          >
            {translate('withdrawal_note3')}
          </li>
        </ul>
      </Box>

      <AvailableBalanceTxt>{translate('available_balance')}</AvailableBalanceTxt>

      {/* Balance */}
      <TypographyCustom sx={{ fontSize: '15px' }}>{`${memberBalance?.currencyCode} ${
        memberBalance?.displayBalance ? fCurrency(memberBalance?.displayBalance) : 0
      }`}</TypographyCustom>

      {/* Amount */}
      <FormBox>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                {translate('bank_name')}
              </td>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                :
              </td>
              <td style={{ padding: '5px 0', width: 'auto', whiteSpace: 'nowrap' }}>
                <CustomInput
                  disabled
                  value={memberBankAccounts?.find((f) => f.id === selectedBankAccount)?.Bank?.name}
                />
              </td>
            </tr>

            <tr>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                {translate('bank_account_name')}
              </td>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                :
              </td>
              <td style={{ padding: '5px 0', width: 'auto', whiteSpace: 'nowrap' }}>
                <CustomInput
                  disabled
                  value={memberBankAccounts?.find((f) => f.id === selectedBankAccount)?.account_name}
                />
              </td>
            </tr>

            <tr>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                {translate('bank_account_no')}
              </td>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                :
              </td>
              <td style={{ padding: '5px 0', width: 'auto', whiteSpace: 'nowrap' }}>
                <CustomInput
                  disabled
                  value={memberBankAccounts?.find((f) => f.id === selectedBankAccount)?.account_number}
                />
              </td>
            </tr>

            <tr>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                {translate('bank_bsb')}
              </td>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                :
              </td>
              <td style={{ padding: '5px 0', width: 'auto', whiteSpace: 'nowrap' }}>
                <CustomInput
                  disabled
                  value={memberBankAccounts?.find((f) => f.id === selectedBankAccount)?.bank_branch}
                />
              </td>
            </tr>

            <tr>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                {translate('pay_id')}
              </td>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                :
              </td>
              <td style={{ padding: '5px 0', width: 'auto', whiteSpace: 'nowrap' }}>
                <CustomInput disabled value={memberBankAccounts?.find((f) => f.id === selectedBankAccount)?.pay_id} />
              </td>
            </tr>

            <tr>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                {translate('withdrawal_amount')}
              </td>
              <td style={{ padding: '5px 5px 5px 0', width: '1%', whiteSpace: 'nowrap', color: '#000', fontSize: 15 }}>
                :
              </td>
              <td style={{ padding: '5px 0', width: 'auto', whiteSpace: 'nowrap' }}>
                <CustomInput
                  name="withdrawAmount"
                  value={fNumberCommaSeparated(withdrawAmount)}
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                  placeholder={translate('amount_withdrawn')}
                  sx={{ background: '#fff' }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {/* <Stack direction="row" alignItems={'center'}>
          <InputLabel>
            <span style={{ color: 'red', marginRight: 5 }}>*</span>
            {translate('amount')}
          </InputLabel>
        </Stack>

        <WithdrawInputBox direction="row">
          <TypographyCustom sx={{ mr: '4px', fontWeight: 700, color: '#000' }}>
            {memberBalance?.currencyCode}
          </TypographyCustom>
          <InputBase
            name="withdrawAmount"
            value={fNumberCommaSeparated(withdrawAmount)}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            placeholder={translate('amount_withdrawn')}
            sx={{ color: '#000', fontWeight: 700, fontSize: '14px' }}
          />
        </WithdrawInputBox> */}

        {withdrawLimitMessage && <WithddrawNote dangerouslySetInnerHTML={{ __html: withdrawLimitMessage }} />}
      </FormBox>

      <Stack direction="row" spacing={'10px'} sx={{ mt: '10px' }}>
        <ActionButton onClick={() => handleConfirm()}>
          <ActionButtonText>{translate('submit')}</ActionButtonText>
        </ActionButton>
        <ActionButton
          sx={{ background: 'linear-gradient(to bottom,#F44336,#B71C1C)', borderColor: '#C62828' }}
          onClick={() => window.location.reload()}
        >
          <ActionButtonText>{translate('cancel')}</ActionButtonText>
        </ActionButton>
      </Stack>

      {memberBankAccounts.length === 0 && <NoBankAccount>{translate('no_bank_account')}</NoBankAccount>}

      {/* {memberBankAccounts.length > 0 ? (
        <Box sx={{ mb: '10px' }}>
          <Stack direction="row" alignItems={'center'}>
            <InputLabel>
              <span style={{ color: 'red', marginRight: 5 }}>*</span>
              {translate('bank_account')}
            </InputLabel>
          </Stack>

          <StyledSelect
            value={selectedBankAccount}
            onChange={(e) => setSelectedBankAccount(e.target.value)}
            disableUnderline={true}
          >
            {memberBankAccounts?.map((item, index) => (
              <option key={index} value={item?.id}>
                {`${item?.account_number} - ${item?.Bank?.name}`}
              </option>
            ))}
          </StyledSelect>
        </Box>
      ) : (
        <NoBankAccount>{translate('no_bank_account')}</NoBankAccount>
      )} */}
      {!memberBankAccounts.length && (
        <button
          onClick={() => dispatch(openAddMemberBankDialog({ open: true, onClose: getMemberBankAccounts }))}
          style={{
            fontSize: '.75rem',
            padding: '5px',
            border: 'none',
            borderRadius: '5px',
            background: '#dd0000',
            color: 'white',
            cursor: 'pointer',
            fontWeight: 600,
            marginTop: '10px',
          }}
        >
          {translate('add_account')}
        </button>
      )}

      {/* <AddAccountBtn onClick={() => dispatch(openAddMemberBankDialog({ open: true, onClose: getMemberBankAccounts }))}>
        {translate('add_account')}
      </AddAccountBtn> */}

      {/* {currencyWithdraw && (
        <Box sx={{ mt: '10px' }}>
          <Grid container spacing={'20px'}>
            {currencyWithdraw.map((item) => makeCurrencyWithdraw(item))}
          </Grid>
        </Box>
      )} */}

      {/* <ConfirmBtnBox container onClick={() => handleConfirm()}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { sm: 'unset', xs: 'center' },
          }}
        >
          <WithdrawText>{translate('withdraw')}</WithdrawText>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          sx={{ display: 'flex', justifyContent: { sm: 'end', xs: 'center' } }}
        >
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Typography sx={{ fontSize: '26px', color: 'white', fontWeight: 700, mr: 2, wordBreak: 'break-all' }}>{`${
              memberBalance?.currencyCode
            } ${fCurrency(withdrawAmount)}`}</Typography>
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="right-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
              style={{ color: 'white' }}
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm154.7 454.5l-246 178c-5.3 3.8-12.7 0-12.7-6.5v-46.9c0-10.2 4.9-19.9 13.2-25.9L566.6 512 421.2 406.8c-8.3-6-13.2-15.6-13.2-25.9V334c0-6.5 7.4-10.3 12.7-6.5l246 178c4.4 3.2 4.4 9.8 0 13z" />
            </svg>
          </Stack>
        </Grid>
      </ConfirmBtnBox> */}

      {openInsufficientBalance && (
        <InsufficientBalanceDialog
          openInsufficientBalance={openInsufficientBalance}
          setOpenInsufficientBalance={setOpenInsufficientBalance}
        />
      )}
      {confirmWithdraw && (
        <ConfirmWithdrawDrawer
          confirmWithdraw={confirmWithdraw}
          withdrawAmount={fCurrency(withdrawAmount)}
          toggleDrawer={toggleDrawer}
          submitWithdraw={submitWithdraw}
          isSubmitting={isSubmitting}
        />
      )}
    </Box>
  );
};

export default Withdraw;
