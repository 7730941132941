import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  activeTab: 'Popular',
  gameCategories: [],
  allGames: [],
};

const slice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action?.payload;
    },
    setGameCategories(state, action) {
      state.gameCategories = action?.payload;
    },
    setAllGames(state, action) {
      state.allGames = action?.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setActiveTab, setGameCategories, setAllGames } = slice.actions;
