import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { Dialog, Box, Paper, useTheme } from '@mui/material';
//
import { varFade } from './variants';

import cssStyles from '../../utils/cssStyles';

// ----------------------------------------------------------------------

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.object,
  variants: PropTypes.object,
};

export default function DialogAnimate({ open = false, variants, onClose, children, sx, maxWidth, ...other }) {
  const theme = useTheme();

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              margin: { xl: 4, lg: 4, md: 4, sm: 16, xs: '8px !important' },
              // width: '100%',
              // maxWidth: {
              //   xl: '444px !important',
              //   lg: '444px !important',
              //   md: '444px !important',
              //   sm: '444px !important',
              //   xs: '100%',
              // },
              maxWidth: `${maxWidth} !important`,
            },
          }}
          // PaperComponent={(props) => (
          //   <Paper
          //     component={m.div}
          //     sx={{
          //       m: { xl: 4, lg: 4, md: 4, sm: 16, xs: '8px !important' },
          //       // width: '100%',
          //       // maxWidth: {
          //       //   xl: '444px !important',
          //       //   lg: '444px !important',
          //       //   md: '444px !important',
          //       //   sm: '444px !important',
          //       //   xs: '100%',
          //       // },
          //       maxWidth: `${maxWidth} !important`,
          //     }}
          //     {...props}
          //     {...(variants ||
          //       varFade({
          //         distance: 120,
          //         durationIn: 0.32,
          //         durationOut: 0.24,
          //         easeIn: 'easeInOut',
          //       }).inUp)}
          //   >
          //     {props.children}
          //   </Paper>
          //   // </Box>
          // )}
          BackdropProps={{ ...cssStyles(theme).bgBlur() }}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
