import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, styled } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Deposit from './deposit/Deposit';
import Withdraw from './withdraw/Withdraw';

import { useDispatch, useSelector } from '../../../redux/store';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import CustomModal from '../../../components/CustomModal';
import useLocales from '../../../hooks/useLocales';

import palette from '../../../theme/palette';

const StyledTabList = styled(TabList)(({ theme }) => ({
  background: palette.transactionDialog.tab.bg,
  width: 'fit-content',
  minHeight: 'unset',
  borderRadius: '16px',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 'unset',
  marginRight: '10px !important',
  padding: '5px 10px',
  fontSize: '14px',
  borderRadius: '16px',
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  borderRadius: 2,
  padding: '50px 24px 24px',
  [theme.breakpoints.only('xs')]: {
    padding: '50px 8px 8px',
  },
}));

const DepositWithdrawDialog = ({ tab = 1 }) => {
  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const [activeTab, setActiveTab] = useState(tab.toString());

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <CustomModal
      open={isOpenTransactionsDialog.open}
      onClose={() => dispatch(openTransactionsDialog({ open: false, isDeposit: true }))}
      isBackdropClosable={false}
      // sx={{ overflow: 'hidden' }}
    >
      <Box
        sx={{
          background: palette.transactionDialog.bg,
          border: `1px solid ${palette.transactionDialog.border}`,
          borderRadius: 2,
        }}
      >
        <InnerContainer>
          <TabContext value={activeTab}>
            <Box sx={{ mb: 4 }}>
              <StyledTabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                // indicatorColor="transparent"
                TabIndicatorProps={{
                  style: { backgroundColor: 'transparent' },
                }}
              >
                <StyledTab
                  label={translate('deposit')}
                  value="1"
                  sx={{
                  border: `1px solid ${palette.transactionDialog.border}`,
                    background: (theme) =>
                      activeTab === '1' ? palette.transactionDialog.tab.active : palette.transactionDialog.tab.inactive,
                    color:
                      activeTab === '1'
                        ? `${palette.transactionDialog.tab.activeTxt} !important`
                        : palette.transactionDialog.tab.inactiveTxt,
                  }}
                />
                <StyledTab
                  label={translate('withdraw')}
                  value="2"
                  sx={{
                    border: `1px solid ${palette.transactionDialog.border}`,
                    background: (theme) =>
                      activeTab === '2' ? palette.transactionDialog.tab.active : palette.transactionDialog.tab.inactive,
                    color:
                      activeTab === '2'
                        ? `${palette.transactionDialog.tab.activeTxt} !important`
                        : palette.transactionDialog.tab.inactiveTxt,
                    marginRight: '0px !important',
                  }}
                />
              </StyledTabList>
            </Box>
            <TabPanel value="1">
              <Deposit />
            </TabPanel>
            <TabPanel value="2">
              <Withdraw />
            </TabPanel>
          </TabContext>
        </InnerContainer>
      </Box>
    </CustomModal>
  );
};

DepositWithdrawDialog.propTypes = {
  tab: PropTypes.number,
};

export default DepositWithdrawDialog;
