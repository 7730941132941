import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const isAuthenticated = localStorage.getItem('accessToken');
  
  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.loginUnprotected} />;
  }
  
  return <>{children}</>;
}
